<template>
  <b-modal
    id="add-credit-card"
    v-model="open"
    modal-class="add-credit-card"
    body-class="p-2"
    centered
    hide-header
    hide-footer
    v-on="$listeners"
  >
    <div class="z-content">
      <div class="side-section d-none d-sm-inline">
        <img
          class="credit-card"
          src="@/assets/img/credit-card.png"
          alt="credit card payment option"
        />
      </div>
      <div class="form-section">
        <div>
          <p class="title">Tarjeta crédito / débito</p>
          <p class="z-message">Aceptamos visa, mastercard y amex</p>
        </div>
        <quick-message
          class="mt-3"
          :show.sync="hasMessage"
          with-icon
          :message="message"
          :type="typeOfMessage"
        />
        <div class="form-container">
          <b-form>
            <b-row>
              <b-col>
                <b-form-group
                  id="card-number-group"
                  label="Número de tarjeta"
                  label-for="amount-input"
                  label-size="sm"
                  :invalid-feedback="invalidFeedbackCardNumber"
                  :state="isValidCardNumber"
                >
                  <b-form-input
                    id="card-number-input"
                    v-model="cardNumber"
                    type="text"
                    :state="isValidCardNumber"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="card-owner-group"
                  label="Nombre de titular"
                  label-size="sm"
                  label-for="card-owner-input"
                  :invalid-feedback="invalidFeedbackOwnerName"
                  :state="isValidOwnerName"
                >
                  <b-form-input
                    id="card-owner-input"
                    v-model="ownerName"
                    type="text"
                    :state="isValidOwnerName"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="month-group"
                  label="Mes"
                  label-size="sm"
                  label-for="month-input"
                >
                  <b-form-select
                    id="month-input"
                    v-model="expiringMonth"
                    :class="{
                      'is-valid': isValidExpiringDate,
                      'is-invalid':
                        !isValidExpiringDate && expiringMonth && expiringYear
                    }"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="(option, index) in monthOptions"
                      :key="`month-option-${option}-${index}`"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="year-group"
                  label="Año"
                  label-size="sm"
                  label-for="year-input"
                >
                  <b-form-select
                    id="year-input"
                    v-model="expiringYear"
                    :class="{
                      'is-valid': isValidExpiringDate,
                      'is-invalid':
                        !isValidExpiringDate && expiringMonth && expiringYear
                    }"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="(option, index) in yearOptions"
                      :key="`year-option-${option}-${index}`"
                      :value="option"
                      >{{ option }}</option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="cvv-group"
                  label="CVV/CVC (3 dígitos)"
                  label-size="sm"
                  label-for="cvv-input"
                  :invalid-feedback="invalidFeedbackCvv"
                  :state="isValidCvv"
                >
                  <b-form-input
                    id="cvv-input"
                    v-model="cvv"
                    type="number"
                    maxlength="3"
                    :state="isValidCvv"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="actions-container">
              <b-col>
                <z-button
                  type="button"
                  variant="secondary"
                  size="lg"
                  @click="closeModal"
                >
                  Cancelar
                </z-button>
                <z-button
                  class="ml-2"
                  type="button"
                  variant="primary"
                  size="lg"
                  :disabled="!isAddCreditCardButtonEnabled"
                  :loading="isRequestingAddCreditCard"
                  @click="addCreditCard"
                >
                  Agregar
                </z-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddCreditCard",

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: false,
      expiringMonth: null,
      expiringYear: null,
      monthOptions: [],
      yearOptions: [],
      cardNumber: null,
      ownerName: null,
      cvv: null,
      nameRegex: /^[a-zA-Z ]{2,30}$/,
      clientId: this.$store.state.user.id,
      isRequestingAddCreditCard: false,
      conekta: null,
      hasError: false,
      hasWarning: false,
      typeOfMessage: "error",
      message: ""
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.hasError || this.hasWarning;
      },
      set(newVal) {
        this.hasError = newVal;
        this.hasWarning = newVal;
      }
    },
    invalidFeedbackCvv() {
      return this.cvv && !this.isValidCvv ? "Formato invalido" : "";
    },
    isValidCvv() {
      return this.cvv && Conekta.card.validateCVC(this.cvv);
    },
    invalidFeedbackCardNumber() {
      return this.cardNumber && !this.isValidCardNumber
        ? "Número de tarjeta inválido"
        : "";
    },
    isValidCardNumber() {
      return this.cardNumber && Conekta.card.validateNumber(this.cardNumber);
    },
    invalidFeedbackOwnerName() {
      return this.ownerName &&
        this.ownerName.length > 0 &&
        !this.nameRegex.test(this.ownerName)
        ? "Nombre inválido"
        : "";
    },
    isValidOwnerName() {
      return (
        this.ownerName &&
        this.ownerName.length > 0 &&
        this.nameRegex.test(this.ownerName)
      );
    },
    isValidForm() {
      return (
        this.isValidCardNumber &&
        this.isValidCvv &&
        this.isValidOwnerName &&
        this.expiringMonth &&
        this.expiringYear &&
        this.conekta.card.validateExpirationDate(
          this.expiringMonth,
          this.expiringYear
        )
      );
    },
    isValidExpiringDate() {
      return (
        this.expiringMonth &&
        this.expiringYear &&
        this.conekta.card.validateExpirationDate(
          this.expiringMonth,
          this.expiringYear
        )
      );
    },
    isAddCreditCardButtonEnabled() {
      return this.isValidForm && !this.isRequestingAddCreditCard;
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.open = newVal;
      }
    }
  },

  beforeMount() {
    this.initConekta();
  },

  created() {
    this.monthOptions = this.createMonthOption();
    this.yearOptions = this.createYearOptions();
  },

  methods: {
    initConekta() {
      this.$store.dispatch("user/loadConekta").then(conekta => {
        this.conekta = conekta;
      });
    },
    createYearOptions() {
      /* These validations where extracted from https://cdn.conekta.io/js/latest/conekta.js */
      const minYear = new Date().getFullYear();
      const maxYear = new Date().getFullYear() + 21;
      const yearList = [];

      for (let year = minYear; year <= maxYear; year++) {
        yearList.push(year);
      }

      return yearList;
    },
    createMonthOption() {
      return [...Array(12).keys()].map(i => (i < 9 ? `0${++i}` : ++i));
    },
    addCreditCard() {
      this.isRequestingAddCreditCard = true;
      const tokenParams = {
        card: {
          number: this.cardNumber,
          name: this.ownerName,
          exp_year: this.expiringYear,
          exp_month: this.expiringMonth,
          cvc: this.cvv
        }
      };

      this.$store
        .dispatch("user/addCreditCard", tokenParams)
        .then(({ data, isNewCreditCard }) => {
          this.$emit("done", { message: "Método de pago agregado" });
          if (isNewCreditCard) {
            this.clearForm();
            this.closeModal();
            /* This needs to be emited, beause this modal will be closed
               and the notification can't be shown */
            this.$emit("credit-card-added", {
              message: `Se agregó una tarjeta con terminación ${data.last4}`
            });
          } else {
            this.hasWarning = true;
            this.typeOfMessage = "info";
            this.message =
              "La tarjeta seleccionada ya existe como método de pago";
          }
        })
        .catch(err => {
          this.$captureError(err);
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message =
            "No se ha podido agregar tu tarjeta. Verifica tus datos";
        })
        .finally(() => {
          this.isRequestingAddCreditCard = false;
        });
    },
    clearForm() {
      this.cardNumber = null;
      this.ownerName = null;
      this.expiringYear = null;
      this.expiringMonth = null;
      this.cvv = null;
    },
    closeModal() {
      this.open = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba($black, 0.16);
}

.side-section {
  padding-right: 2em;

  .credit-card {
    margin-top: 6px;
    padding: 10px;
    min-width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: rgba($royal-blue, 0.06);
  }
}

.z-content {
  display: flex !important;
  justify-content: center;
  padding: 1em 2em 2em 2em;

  .title {
    color: $nero;
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
  }

  .z-message {
    font-size: 12px;
    color: $dim-gray;
    margin: 0px;
  }

  .payment-method-sidebar {
    width: 100px;

    .payment-icon {
      height: 50px;
      width: 50px;
      background: $white-smoke;
      border-radius: 2px;
      padding: 0.5em;
      line-height: 34px;

      img {
        width: 100%;
      }
    }
  }

  .form-container {
    margin-top: 24px;

    ::v-deep .col-form-label-sm {
      padding-bottom: 0;
    }

    ::v-deep .form-control::placeholder {
      font-size: 12px;
      color: $dim-gray;
    }

    ::v-deep label {
      margin-bottom: 0;
    }

    .form-control {
      width: 100% !important;
    }

    .actions-container {
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: 576px) {
  .z-content {
    padding: 1em 5em 2em 2em;

    .form-container {
      min-width: 305px;
    }
  }
}
</style>
